<template>
  <section class="dados">
    <div class="principal">
      <Svgs nome="usuario" />
      <h2>{{ contarProdutores }} produtores</h2>
      <p>No total a LevelMember tem {{ contarProdutores }} produtores cadastrados</p>
    </div>
    <!-- Acesso Liberado -->
    <div class="titulo liberado">
      <div class="icone">
        <Svgs nome="check" />
      </div>
      <h2>{{ contarLiberados }} produtores</h2>
      <p>Produtores com acesso liberado na LevelMember</p>
    </div>
    <!-- Lista -->
    <div class="quadros liberado">
      <div class="quadro">
        <img src="https://central-statics.nyc3.digitaloceanspaces.com/levelmember/imagens/planos/free-ico.svg" />
        <h3>{{ contarFree }}</h3>
        <p>Plano Free</p>
        <h4>1 site</h4>
        <h4>50 alunos</h4>
      </div>
      <div class="quadro">
        <img src="https://central-statics.nyc3.digitaloceanspaces.com/levelmember/imagens/planos/essencial-ico.svg" />
        <h3>{{ contarEssencial }}</h3>
        <p>Plano Essencial</p>
        <h4>1 área</h4>
        <h4>4.000 alunos</h4>
      </div>
      <div class="quadro">
        <img src="https://central-statics.nyc3.digitaloceanspaces.com/levelmember/imagens/planos/pro-ico.svg" />
        <h3>{{ contarPro }}</h3>
        <p>Plano Pro</p>
        <h4>3 áreas</h4>
        <h4>15.000 alunos</h4>
      </div>
      <div class="quadro">
        <img src="https://central-statics.nyc3.digitaloceanspaces.com/levelmember/imagens/planos/expert-ico.svg" />
        <h3>{{ contarExpert }}</h3>
        <p>Plano Expert</p>
        <h4>15 áreas</h4>
        <h4>100.000 alunos</h4>
      </div>
    </div>
    <!-- Acesso bloqueado -->
    <div class="titulo bloqueado">
      <div class="icone">
        <Svgs nome="x" />
      </div>
      <h2>{{ contarBloqueados }} produtores</h2>
      <p>Produtores com acesso bloqueado na LevelMember</p>
    </div>
    <!-- Lista -->
    <div class="quadros bloqueado">
      <div class="quadro">
        <img src="https://central-statics.nyc3.digitaloceanspaces.com/levelmember/imagens/planos/essencial-ico.svg" />
        <h3>{{ contarEssencialBloqueado }}</h3>
        <p>Plano Essencial</p>
        <h4>1 área</h4>
        <h4>4.000 alunos</h4>
      </div>
      <div class="quadro">
        <img src="https://central-statics.nyc3.digitaloceanspaces.com/levelmember/imagens/planos/pro-ico.svg" />
        <h3>{{ contarProBloqueado }}</h3>
        <p>Plano Pro</p>
        <h4>3 áreas</h4>
        <h4>15.000 alunos</h4>
      </div>
      <div class="quadro">
        <img src="https://central-statics.nyc3.digitaloceanspaces.com/levelmember/imagens/planos/expert-ico.svg" />
        <h3>{{ contarExpertBloqueado }}</h3>
        <p>Plano Expert</p>
        <h4>15 áreas</h4>
        <h4>100.000 alunos</h4>
      </div>
    </div>
  </section>
</template>

<script setup>
import { computed } from 'vue'
import { useStoreProdutores } from '@stores'
import Svgs from '@svgs'

const storeProdutores = useStoreProdutores()

const contarLiberados = computed(() => {
  return storeProdutores.produtores.filter((produtor) => produtor.assinatura?.ativo).length
})

const contarProdutores = computed(() => {
  return storeProdutores.produtores.length
})

const contarFree = computed(() => {
  return storeProdutores.produtores.filter((produtor) => produtor.assinatura?.plano?.toLowerCase() === 'free' && produtor.assinatura?.ativo).length
})

const contarEssencial = computed(() => {
  return storeProdutores.produtores.filter((produtor) => produtor.assinatura?.plano?.toLowerCase() === 'essencial' && produtor.assinatura?.ativo).length
})

const contarPro = computed(() => {
  return storeProdutores.produtores.filter((produtor) => produtor.assinatura?.plano?.toLowerCase() === 'pro' && produtor.assinatura?.ativo).length
})

const contarExpert = computed(() => {
  return storeProdutores.produtores.filter((produtor) => produtor.assinatura?.plano?.toLowerCase() === 'expert' && produtor.assinatura?.ativo).length
})

const contarBloqueados = computed(() => {
  return storeProdutores.produtores.filter((produtor) => !produtor.assinatura?.ativo).length
})

const contarEssencialBloqueado = computed(() => {
  return storeProdutores.produtores.filter((produtor) => produtor.assinatura?.plano?.toLowerCase() === 'essencial' && !produtor.assinatura?.ativo).length
})

const contarProBloqueado = computed(() => {
  return storeProdutores.produtores.filter((produtor) => produtor.assinatura?.plano?.toLowerCase() === 'pro' && !produtor.assinatura?.ativo).length
})

const contarExpertBloqueado = computed(() => {
  return storeProdutores.produtores.filter((produtor) => produtor.assinatura?.plano?.toLowerCase() === 'expert' && !produtor.assinatura?.ativo).length
})
</script>

<style scoped>
section.dados {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 20px 20px 0 20px;
  min-height: 100dvh;
}

.principal {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border-radius: 10px;
  background-color: var(--cor-cinza-2);
  width: 100%;
  margin: 0 0 20px 0;
}

.principal svg {
  width: 15px;
  min-width: 15px;
  fill: var(--cor-branco);
}

.principal h2 {
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco);
  margin: 0 10px 0 15px;
}

.principal p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza-5);
}

.titulo {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 0 0 20px 0;
}

.titulo .icone {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 10px;
  background-color: var(--cor-cinza-3);
}

.titulo .icone svg {
  width: 15px;
  min-width: 15px;
  fill: var(--cor-branco-fixo);
}

.titulo.liberado .icone {
  background-color: var(--cor-verde);
}

.titulo.bloqueado .icone {
  background-color: var(--cor-vermelho);
}

.titulo h2 {
  font-family: var(--bold);
  font-size: var(--f4);
  color: var(--cor-branco);
  margin: 0 10px 0 15px;
}

.titulo p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza-5);
}

.quadros {
  display: flex;
  justify-content: space-between;
  width: 100%;
  border: 1px solid var(--cor-cinza-4);
  border-radius: 10px;
  margin: 0 0 40px 0;
}

.quadros.bloqueado .quadro {
  width: 33.33%;
}

.quadro:nth-child(1) {
  border-left: none;
}

.quadro {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 25%;
  border-left: 1px solid var(--cor-cinza-4);
  padding: 80px 20px;
}

.quadro img {
  max-height: 30px;
  min-height: 30px;
}

.quadro h3 {
  font-family: var(--bold);
  font-size: var(--f4);
  color: var(--cor-branco);
  margin: 30px 0 0 0;
}

.quadro p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
  margin: 10px 0 0 0;
}

.quadro h4 {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza-5);
  margin: 5px 0 0 0;
}

/* Responsivo */
@media screen and (max-width: 1000px) {
  section.dados {
    padding: 20px;
  }

  .principal {
    flex-direction: column;
    padding: 40px 20px;
    margin: 0 0 30px 0;
  }

  .principal svg {
    width: 20px;
    min-width: 20px;
  }

  .principal h2 {
    font-size: var(--f3);
    margin: 15px 0 5px 0;
  }

  .principal p {
    width: 100%;
    max-width: 230px;
    line-height: 1.5;
    text-align: center;
  }

  .titulo {
    flex-direction: column;
    align-items: flex-start;
  }

  .titulo h2 {
    margin: 15px 0 5px 0;
  }

  .quadros {
    justify-content: flex-start;
    padding: 20px;
    overflow-y: scroll;
    scrollbar-width: none;
  }

  .quadros::-webkit-scrollbar {
    display: none;
  }

  .quadro {
    width: 190px;
    min-width: 190px;
    padding: 30px 15px;
    margin: 0 20px 0 0;
  }

  .quadro img {
    max-width: 25px;
  }
}
</style>
