<template>
  <Modal nome="resetarSenha">
    <div class="info">
      <div class="foto" :style="`background-image: url('${storeProdutores.produtor?.imagemPerfil || 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/icones/placeholder-perfil.svg'}')`"></div>
      <h3>Deseja resetar a senha de {{ storeProdutores.produtor?.nome }} para 123456789?</h3>
    </div>

    <div class="inputAtivacao" :class="{ ativo: state.confirmar }" @click="state.confirmar = !state.confirmar">
      <span>
        <Svgs nome="check" />
      </span>
      <p>Sim, desejo resetar a senha</p>
    </div>

    <BotaoExcluir nome="Resetar Senha" @click="resetarSenha()" />
  </Modal>
</template>

<script setup>
import { reactive, inject, computed, watch, onMounted } from 'vue'
import { useStoreProdutores, useStoreModal } from '@stores'
import Modal from '@/components/global/modal/Modal.vue'
import BotaoExcluir from '@/components/global/elementos/BotaoExcluir.vue'
import Svgs from '@svgs'

const emitter = inject('emitter')
const storeProdutores = useStoreProdutores()
const storeModal = useStoreModal()
const dadosModal = computed(() => storeModal.dadosDoModal('resetarSenha'))

const state = reactive({
  confirmar: false,
  id: ''
})

document.addEventListener('keydown', (event) => {
  if (event.key === 'Escape') fecharModal()
})

function resetarSenha() {
  if (state.confirmar === true) {
    const idProdutor = storeProdutores.produtor?._id
    storeProdutores.resetarSenha(idProdutor)
    storeModal.fecharModal('resetarSenha')
  } else {
    const mensagem = 'Marque a pergunta para resetar a senha'
    emitter.emit('mostrarAlerta', { tipo: 'negativo', mensagem })
  }
}

async function carregarId() {
  if (dadosModal.value.id) {
    await storeProdutores.receberPorId(dadosModal.value.id)
  }
}

function limparCampos() {
  state.id = ''
  state.confirmar = false
}

onMounted(() => {
  watch(
    dadosModal,
    (novoValor) => {
      if (novoValor.id) {
        carregarId()
      }
    },
    { immediate: true }
  )
  emitter.on('limparCampos', limparCampos)
})
</script>

<style scoped>
.info {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin: 0 0 20px 0;
}
.info .foto {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  margin: 0 10px 0 0;
}
.info h3 {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
}
/* Responsivo */
@media screen and (max-width: 1000px) {
  section.modal {
    align-items: flex-start;
    padding: 10px;
  }
  section.modal .titulo {
    padding: 20px;
  }
  section.modal .botao {
    padding: 20px;
  }
  section.modal .campos {
    padding: 20px 20px 0 20px;
  }
}
</style>
