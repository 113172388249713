<template>
  <section class="modal" :class="{ mostrar: mostrarModalSites }">
    <div class="conteudo">
      <div class="titulo">
        <div class="foto" :style="`background-image: url('${storeProdutores.modals.imagemPerfil || 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/icones/placeholder-perfil.svg'}')`"></div>
        <div class="texto">
          <h3>Sites do usuário</h3>
          <p>{{ storeProdutores.modals.email }}</p>
        </div>
        <button @click="fecharModal">
          <span class="um"></span>
          <span class="dois"></span>
        </button>
      </div>

      <div class="lista">
        <h6 v-if="!storeAreas.areasProdutor.length">Nenhuma área criada pelo produtor</h6>
        <div v-for="area in storeAreas.areasProdutor" :key="area.hotlink" class="site">
          <div class="nome">
            <h4 class="online">
              <span></span>
              Online
            </h4>
            <h3>mber.live/{{ area.hotlink }}</h3>
            <p :class="{ com: area.dominioPersonalizado }">{{ area.dominioPersonalizado || 'Sem domínio' }}</p>
            <h5>Criado {{ formatarData(area.dataRegistro) }}</h5>
          </div>
          <div class="botoes">
            <button @click="verSite(area.hotlink)">
              <Svgs nome="link" />
              Hotlink
            </button>
            <button v-if="area.dominioPersonalizado" @click="verDominio(area.dominioPersonalizado)">
              <Svgs nome="dominio" />
              Domínio
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script setup>
import { storeToRefs } from 'pinia'
import { useStoreProdutores, useStoreAreas } from '@stores'
import { helperRedirect } from '@helpers'
import Svgs from '@svgs'

const storeProdutores = useStoreProdutores()
const storeAreas = useStoreAreas()

const { mostrarModalSites } = storeToRefs(storeProdutores)

function fecharModal() {
  storeProdutores.mostrarModalSites = false
  storeAreas.areasProdutor = []
}

document.addEventListener('keydown', (event) => {
  if (event.key === 'Escape') fecharModal()
})

function verSite(hotlink) {
  const url = `https://mber.live/${hotlink}`
  helperRedirect.redirecionar(url)
}

function verDominio(dominio) {
  const url = `https://${dominio}`
  helperRedirect.redirecionar(url)
}

function formatarData(data) {
  const agora = new Date()
  const dataNotificacao = new Date(data)
  const diferencaMs = agora - dataNotificacao
  const diferencaMinutos = Math.floor(diferencaMs / 60000)
  const diferencaHoras = Math.floor(diferencaMs / 3600000)

  if (diferencaMinutos < 1) {
    return 'há segundos'
  } else if (diferencaMinutos < 60) {
    return `há ${diferencaMinutos} minuto${diferencaMinutos > 1 ? 's' : ''}`
  } else if (diferencaHoras < 24) {
    return `há ${diferencaHoras} hora${diferencaHoras > 1 ? 's' : ''}`
  } else {
    const day = String(dataNotificacao.getDate()).padStart(2, '0')
    const month = String(dataNotificacao.getMonth() + 1).padStart(2, '0')
    const year = dataNotificacao.getFullYear()
    const hours = String(dataNotificacao.getHours()).padStart(2, '0')
    const minutes = String(dataNotificacao.getMinutes()).padStart(2, '0')

    return `${day}/${month}/${year} às ${hours}h${minutes}`
  }
}
</script>

<style scoped>
section.modal {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100dvh;
  background-color: #00000071;
  padding: 30px;
  z-index: 11;
  opacity: 0;
  visibility: visible;
  pointer-events: none;
  transition: all 0.3s;
}

section.modal.mostrar {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
}

section.modal.fechar {
  opacity: 0;
  visibility: visible;
  pointer-events: none;
}

.conteudo {
  width: 100%;
  max-height: 100%;
  max-width: 900px;
  background-color: var(--cor-cinza-1);
  border: 2px solid var(--cor-cinza-3);
  border-radius: 10px;
  overflow-y: scroll;
}

.conteudo .titulo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 30px;
  border-bottom: 2px solid var(--cor-cinza-3);
}

.conteudo .titulo .foto {
  width: 30px;
  min-width: 30px;
  height: 30px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  margin: 0 15px 0 0;
}

.conteudo .titulo .texto h3 {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
}

.conteudo .titulo .texto p {
  font-family: var(--light);
  font-size: var(--f2);
  color: var(--cor-cinza-5);
  line-height: 1.5;
}

.conteudo .titulo button {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 40px;
  height: 40px;
  background: transparent;
  margin-left: auto;
  transition: all 0.5s;
}

.conteudo .titulo button span {
  position: absolute;
  width: 40px;
  height: 3px;
  background-color: var(--cor-vermelho);
  transition: all 0.5s;
}

.conteudo .titulo button span.um {
  transform: rotate(45deg);
}

.conteudo .titulo button span.dois {
  transform: rotate(-45deg);
}

.conteudo .titulo button:hover {
  transform: rotate(90deg);
}

.conteudo .titulo button:hover span {
  background-color: var(--cor-branco);
}

.lista {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  padding: 30px 30px 30px 30px;
}

.lista h6 {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-vermelho);
  width: 100%;
  text-align: center;
}

.lista .site {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: calc(50% - 5px);
  background-color: var(--cor-cinza-2);
  border-radius: 10px;
  padding: 15px;
  margin: 10px 0 0 0;
}
.lista .site .nome {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}
.lista .site .nome h3 {
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco);
  margin: 10px 0;
}
.lista .site .nome p.com {
  color: var(--cor-azul);
}
.lista .site .nome p {
  font-family: var(--light);
  font-size: var(--f2);
  color: var(--cor-cinza-5);
}
.lista .site .nome h5 {
  font-family: var(--light);
  font-size: var(--f2);
  color: var(--cor-cinza-5);
  margin: 10px 0 20px 0;
}
.lista .site .nome h4 {
  display: flex;
  align-items: center;
  font-family: var(--light);
  font-size: var(--f1);
  color: var(--cor-branco);
  margin: 5px 0 0 0;
}
.lista .site .nome h4.online {
  color: var(--cor-verde);
}
.lista .site .nome h4.online span {
  background-color: var(--cor-verde);
}
.lista .site .nome h4.offline {
  color: var(--cor-cinza-5);
}
.lista .site .nome h4.offline span {
  background-color: var(--cor-cinza-4);
}
.lista .site .nome h4 span {
  width: 5px;
  min-width: 5px;
  height: 5px;
  min-height: 5px;
  border-radius: 50%;
  background-color: var(--cor-branco);
  margin: 0 5px 0 0;
}
.lista .site .botoes {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}
.lista .site .botoes button {
  display: flex;
  align-items: center;
  background-color: var(--cor-cinza-3);
  padding: 15px;
  border-radius: 10px;
  font-family: var(--light);
  font-size: var(--f1);
  color: var(--cor-cinza-5);
  transition: all 0.3s;
  margin: 0 10px 0 0;
}
.lista .site .botoes button svg {
  width: 12px;
  min-width: 12px;
  fill: var(--cor-branco);
  margin: 0 10px 0 0;
}
.lista .site .botoes button:hover {
  color: var(--cor-branco);
  background-color: var(--cor-cinza-4);
}

.usuarios {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: fixed;
  top: 30px;
  left: 30px;
  width: 100%;
  max-width: 500px;
  height: calc(100dvh - 150px);
  max-height: calc(100dvh - 150px);
  min-height: calc(100dvh - 150px);
  background-color: var(--cor-cinza-1);
  border: 1px solid var(--cor-cinza-3);
  border-radius: 10px;
  padding: 30px 30px 0 30px;
  animation: fadeIn 0.3s linear;
  overflow-y: scroll;
}

.usuarios::-webkit-scrollbar-track {
  background-color: transparent;
}

.usuarios .topo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 0 20px 0;
}

.usuarios .topo h3 {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
}

.usuarios .topo button {
  background-color: transparent;
}

.usuarios .topo button svg {
  width: 25px;
  min-width: 25px;
  fill: var(--cor-vermelho);
  transition: all 0.3s;
}

.usuarios .topo button:hover svg {
  fill: var(--cor-branco);
}

.usuarios .input {
  margin: 0 0 10px 0;
}

.usuarios button.transferir {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco-fixo);
  background-color: var(--cor-azul);
  width: 100%;
  padding: 20px 20px;
  border-radius: 10px;
  margin: 0 0 20px 0;
  position: relative;
  transition: all 0.3s;
}

.usuarios button.transferir:hover {
  background-color: var(--cor-azul-escuro);
}

.usuarios .listaUsuarios {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow-y: scroll;
  max-height: 450px;
  min-height: 450px;
  width: 100%;
}

.usuarios .usuario.ativo {
  background-color: var(--cor-cinza-3);
  border: 1px solid var(--cor-azul);
}

.usuarios .usuario {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: var(--cor-cinza-2);
  border: 1px solid var(--cor-cinza-2);
  border-radius: 10px;
  padding: 20px 10px 20px 20px;
  margin: 0 0 10px 0;
  transition: all 0.3s;
}

.usuarios .usuario .info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.usuarios .usuario .foto {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
}

.usuarios .usuario .nome {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: auto;
  padding: 0 0 0 15px;
}

.usuarios .usuario .nome h3 {
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco);
}

.usuarios .usuario .nome .plano {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0 0 0;
}

.usuarios .usuario .nome .plano h4 {
  font-family: var(--light);
  font-size: var(--f1);
  color: var(--cor-branco);
  text-transform: capitalize;
}

.usuarios .usuario .nome .plano img {
  max-height: 12px;
  max-height: 12px;
}

.usuarios .usuario .nome .plano .estado {
  padding: 2px 4px;
  border-radius: 50px;
  background-color: #f53a3a34;
  margin: 0 5px;
}

.usuarios .usuario .nome .plano .estado.ativo {
  background-color: #22bc5832;
}

.usuarios .usuario .nome .plano .estado.ativo p {
  color: var(--cor-verde);
}

.usuarios .usuario .nome .plano .estado p {
  font-size: var(--f0);
  font-family: var(--bold);
  color: var(--cor-vermelho);
  margin: 0;
  line-height: 1;
}

.usuarios .usuario .nome p {
  font-family: var(--light);
  font-size: var(--f1);
  color: var(--cor-cinza-5);
  line-height: 1.5;
}

.usuarios .usuario .botoes {
  display: flex;
  align-items: center;
}
.usuarios .usuario .botoes button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: transparent;
}
.usuarios .usuario .botoes button p {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-cinza-5);
  margin: 12px 0 0 0;
  transition: all 0.3s;
}
.usuarios .usuario .botoes button.estado {
  flex-direction: row;
  border-left: none;
  width: auto;
  pointer-events: none;
}
.usuarios .usuario .botoes button.estado.ativo p {
  color: var(--cor-verde);
}
.usuarios .usuario .botoes button.estado p {
  color: var(--cor-vermelho);
  margin: 0 20px 0 5px;
}
.usuarios .usuario .botoes button span {
  width: 5px;
  min-width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: var(--cor-vermelho);
  box-shadow: 0 10px 30px 0 var(--cor-vermelho);
}
.usuarios .usuario .botoes button.ativo span {
  background-color: var(--cor-verde);
  box-shadow: 0 10px 30px 0 var(--cor-verde);
}
.usuarios .usuario .botoes button svg {
  width: 15px;
  min-width: 15px;
  max-height: 15px;
  fill: var(--cor-cinza-5);
  transition: all 0.3s;
}

/* Responsivo */
@media screen and (max-width: 1000px) {
  section.modal {
    align-items: flex-start;
    padding: 10px;
  }

  section.modal .titulo {
    padding: 20px;
  }

  .lista {
    padding: 20px;
  }

  .lista .site {
    width: 100%;
    padding: 20px;
  }

  .usuarios {
    top: 10px;
    left: 10px;
    width: calc(100% - 20px);
    max-width: calc(100% - 20px);
    height: calc(100dvh - 20px);
    max-height: calc(100dvh - 20px);
    min-height: calc(100dvh - 20px);
    padding: 20px;
  }
}
</style>
